<style scoped></style>

<template>
  <Table :height="height" :data="dataList" :columns="columns" stripe :loading="loading">
    <template v-slot:goodsId="{ row }">
      <Tooltip>
        <Tag class="noborder" :color="row.goodsStatusData.color">
          {{ row.goodsName }}
        </Tag>
        <template v-slot:content>
          <div>{{ row.goodsStatusData.label }}</div>
          <div>{{ row.goodsGgxh }}</div>
          <div>{{ row.goodsCode }}</div>
        </template>
      </Tooltip>
    </template>
    <template v-slot:volume="{ row }">
      {{ row.volume }}{{ row.goodsUnit || '' }}
    </template>
    <template v-slot:dealVolume="{ row }">
      {{ row.dealVolume }}{{ row.goodsUnit || '' }}
    </template>
    <template v-slot:isEnd="{ row }">
      <Tag class="noborder" :color="row.isEnd ? 'blue' : 'default'">{{ row.isEnd ? '已完成' : '未完成' }}</Tag>
    </template>
    <template v-slot:action="{ row }">
      <Poptip transfer confirm @on-ok="$emit('repeal', row)" title="确定撤销吗？">
        <Button>撤销</Button>
      </Poptip>
    </template>
  </Table>
</template>

<script>
const columns = Object.freeze([
  { title: '实物码', key: 'code' },
  { title: '物品', key: 'goodsId', slot: 'goodsId' },
  { title: '物品使用类别', key: 'goodsUseTypeText' },
  { title: '数量', key: 'volume', slot: 'volume' },
  { title: '已处理数量', key: 'dealVolume', slot: 'dealVolume' },
  { title: '完成时间', key: 'createTime', tooltip: true, minWidth: 180 },
  { title: '处理人', key: 'userName', tooltip: true, minWidth: 100 },
  { title: '状态', key: 'isEnd', slot: 'isEnd' },
  { title: '操作', key: 'action', slot: 'action' },
])
export default {
  props: {
    height: { type: Number, default: undefined },
    dataList: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false }
  },
  data () {
    return { columns }
  }
}
</script>
